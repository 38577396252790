import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ManageTeamMembers.scss"; // Create a SCSS file similar to your existing styles

const API_URL = process.env.REACT_APP_API_BASE_URL;

function ManageTeamMembers() {
  const [fullname, setFullname] = useState("");
  const [designation, setDesignation] = useState("");
  const [category, setCategory] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [image, setImage] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  // Fetch all team members
  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/teammembers`);
      setTeamMembers(response.data);
      console.log(teamMembers, "This is the data");
    } catch (error) {
      console.error("Error fetching team members:", error);
      alert("Failed to fetch team members. Please try again.");
    }
  };

  // Handle file change for image upload
  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  // Handle form submission for adding/updating team members
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fullname", fullname);
    formData.append("designation", designation);
    formData.append("category", category);
    formData.append("linkedinUrl", linkedinUrl);
    formData.append("image", image);

    try {
      if (editId) {
        await axios.put(`${API_URL}/api/teammembers/${editId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Team member updated successfully!");
      } else {
        // Add new team member
        await axios.post(`${API_URL}/api/teammembers`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Team member added successfully!");
      }
      fetchTeamMembers();
      resetForm();
    } catch (error) {
      console.error("Error saving team member:", error);
      alert("Failed to save team member. Please try again.");
    }
  };

  // Handle delete team member
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/api/teammembers/${id}`);
      fetchTeamMembers();
    } catch (error) {
      console.error("Error deleting team member:", error);
      alert("There was an error deleting the team member!");
    }
  };

  // Handle edit team member
  const handleEdit = (teamMember) => {
    setFullname(teamMember.fullname);
    setDesignation(teamMember.designation);
    setCategory(teamMember.category);
    setLinkedinUrl(teamMember.linkedinUrl);
    setEditId(teamMember.id);
  };

  // Reset form fields
  const resetForm = () => {
    setFullname("");
    setDesignation("");
    setCategory("");
    setLinkedinUrl("");
    setImage(null);
    setEditId(null);
  };

  return (
    <div className="member-main-div">
      <div className="add-team-member">
        <h1 className="title-member">
          {editId ? "Update Team Member" : "Add Team Member"}
        </h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Full Name:</label>
            <input
              className="input-members"
              type="text"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              placeholder="Full Name"
              required
            />
          </div>
          <div>
            <label>Designation:</label>
            <input
              className="input-members"
              type="text"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
              placeholder="Designation"
              required
            />
          </div>
          <div>
            <label>Category:</label>
            <input
              className="input-members"
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              placeholder="Category"
              required
            />
          </div>
          <div>
            <label>LinkedIn URL:</label>
            <input
              className="input-members"
              type="url"
              value={linkedinUrl}
              onChange={(e) => setLinkedinUrl(e.target.value)}
              placeholder="LinkedIn URL"
            />
          </div>
          <div>
            <label>Image:</label>
            <input type="file" onChange={handleFileChange} required={!editId} />
          </div>
          <button className="button-add-team" type="submit">
            {editId ? "Update Team Member" : "Add Team Member"}
          </button>
        </form>
      </div>

      <div className="team-members-container">
        <h2 className="title-member-result">Team Members</h2>
        {teamMembers && teamMembers.length > 0 ? (
          teamMembers.map((teamMember) => (
            <div key={teamMember.id} className="team-member-card">
              <div className="team-member-info">
                <img
                  className="team-member-image"
                  src={`${API_URL}/api/teammembers/${teamMember.id}/image`} // Ensure this matches the backend route
                  alt={teamMember.fullname}
                />
                <div>
                  <span className="team-member-name">
                    {teamMember.fullname}
                  </span>
                  <span className="team-member-designation">
                    {teamMember.designation}
                  </span>
                  <span className="team-member-category">
                    {teamMember.category}
                  </span>
                  {teamMember.linkedinUrl && (
                    <a
                      href={teamMember.linkedinUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="team-member-linkedin"
                    >
                      LinkedIn
                    </a>
                  )}
                </div>
              </div>
              <div className="team-member-actions">
                <button onClick={() => handleEdit(teamMember)}>Edit</button>
                <button onClick={() => handleDelete(teamMember.id)}>
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No team members found.</p>
        )}
      </div>
    </div>
  );
}

export default ManageTeamMembers;
