import React, { useState, useEffect } from "react";
import "./AddJob.scss";

const AddJob = () => {
  const [jobData, setJobData] = useState({
    title: "",
    location: "",
    workSchedule: "",
    duties: "",
    qualifications: "",
    preferredSkills: "",
    compensationAndBenefits: "",
    description: "",
    requirements: "",
    expectedSalary: "",
  });

  const [jobs, setJobs] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [jobIdToEdit, setJobIdToEdit] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/jobs`);
      const data = await response.json();
      setJobs(data);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobData({
      ...jobData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/jobs${editMode ? `/${jobIdToEdit}` : ""}`,
        {
          method: editMode ? "PUT" : "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(jobData),
        }
      );

      if (response.ok) {
        alert(
          editMode ? "Job updated successfully!" : "Job added successfully!"
        );
        setJobData({
          title: "",
          location: "",
          workSchedule: "",
          duties: "",
          qualifications: "",
          preferredSkills: "",
          compensationAndBenefits: "",
          description: "",
          requirements: "",
          expectedSalary: "",
        });
        fetchJobs(); // Refresh the job list
        setEditMode(false);
      } else {
        alert("Failed to submit job. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting job:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleEdit = (job) => {
    setJobData(job);
    setEditMode(true);
    setJobIdToEdit(job.id);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/jobs/${id}`, {
        method: "DELETE",
      });

      if (response.ok) {
        alert("Job deleted successfully!");
        fetchJobs(); // Refresh the job list
      } else {
        alert("Failed to delete job. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting job:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="add-job-container">
      <form onSubmit={handleSubmit}>
        <h2>{editMode ? "Edit Job" : "Add New Job"}</h2>
        <input
          type="text"
          name="title"
          placeholder="Job Title"
          value={jobData.title}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="location"
          placeholder="Job Location"
          value={jobData.location}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="workSchedule"
          placeholder="Work Schedule"
          value={jobData.workSchedule}
          onChange={handleChange}
          required
        />
        <textarea
          name="duties"
          placeholder="Job Duties"
          value={jobData.duties}
          onChange={handleChange}
          required
        />
        <textarea
          name="qualifications"
          placeholder="Qualifications"
          value={jobData.qualifications}
          onChange={handleChange}
          required
        />
        <textarea
          name="preferredSkills"
          placeholder="Preferred Skills"
          value={jobData.preferredSkills}
          onChange={handleChange}
        />
        <textarea
          name="compensationAndBenefits"
          placeholder="Compensation and Benefits"
          value={jobData.compensationAndBenefits}
          onChange={handleChange}
          required
        />
        <textarea
          name="description"
          placeholder="Job Description"
          value={jobData.description}
          onChange={handleChange}
          required
        />
        <textarea
          name="requirements"
          placeholder="Job Requirements"
          value={jobData.requirements}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="expectedSalary"
          placeholder="Expected Salary"
          value={jobData.expectedSalary}
          onChange={handleChange}
          required
        />
        <button type="submit">{editMode ? "Update Job" : "Add Job"}</button>
      </form>

      <div className="job-list">
        <h2>Current Jobs</h2>
        {jobs.map((job) => (
          <div className="job-item" key={job.id}>
            <h3>{job.title}</h3>
            <p>
              <strong>Location:</strong> {job.location}
            </p>
            <p>
              <strong>Work Schedule:</strong> {job.workSchedule}
            </p>
            <p>
              <strong>Duties:</strong> {job.duties}
            </p>
            <p>
              <strong>Qualifications:</strong> {job.qualifications}
            </p>
            <p>
              <strong>Preferred Skills:</strong> {job.preferredSkills}
            </p>
            <p>
              <strong>Compensation and Benefits:</strong>{" "}
              {job.compensationAndBenefits}
            </p>
            <p>
              <strong>Description:</strong> {job.description}
            </p>
            <p>
              <strong>Requirements:</strong> {job.requirements}
            </p>
            <p>
              <strong>Expected Salary:</strong> ${job.expectedSalary}
            </p>
            <button onClick={() => handleEdit(job)}>Edit</button>
            <button onClick={() => handleDelete(job.id)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddJob;
