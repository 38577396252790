import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FaUsers,
  FaLocationArrow,
  FaSignOutAlt,
  FaHome,
  FaBook,
  FaImage,
} from "react-icons/fa";

import "./Sidebar.scss";

function Sidebar() {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("auth");
    navigate("/login");
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <div className="sidebar-top">
        <h2 className="admin-panel">Admin Panel</h2>
        <div className="hr-line"></div>
        <nav>
          <div className="section-label">Main</div>
          <ul>
            <li>
              <NavLink to="/admin/dashboard" activeClassName="active">
                <FaHome className="icon" />
                <p className="names-cat">Dashboard</p>
              </NavLink>
            </li>
          </ul>
          <div className="section-label">General</div>
          <ul>
            <li>
              <NavLink to="/admin/add-job" activeClassName="active">
                <FaUsers className="icon" />
                <p className="names-cat">Job Description</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/add-team" activeClassName="active">
                <FaUsers className="icon" />
                <p className="names-cat">Team Member</p>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <button onClick={handleLogout} className="logout-button">
        <FaSignOutAlt className="icon" />
        <span className="logout-name">Logout</span>
      </button>
    </div>
  );
}

export default Sidebar;
